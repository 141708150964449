import './set-public-path';
import { getUserSettings } from './requests';
import { merge } from './merge';
import { Node } from '@netvision/lib-widget-renderer';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
let originalTree: Node = null!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
let currentTree: Node = null!;

const noTenantTreeAppend = (branch: any) => {
  if (branch.props) {
    branch.props.noTenant = true;
  }
  const inners = branch?.areas || branch?.children;
  if (inners) {
    inners.forEach((item: any) => noTenantTreeAppend(item));
  }
};

export const applyUserSetting = (widgetTree: Node & { props: any }): Promise<Node> => {
  originalTree = widgetTree;

  if (widgetTree.props.noTenant) {
    noTenantTreeAppend(originalTree);
    return Promise.resolve(originalTree);
  }

  return getUserSettings()
    .then((settings) => {
      if (typeof settings === 'undefined') {
        currentTree = widgetTree;
      } else {
        currentTree = merge(widgetTree, settings.widgetTreeOverride);
      }
    })
    .catch(() => (currentTree = widgetTree))
    .then(() => currentTree);
};

export const getWidgetTrees = (): { original: Node; current: Node } => {
  return {
    original: originalTree,
    current: currentTree
  };
};

export * from './requests';
