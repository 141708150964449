import { repositoryGetter } from '@netvision/lib-api-repo';
import { JsonPatch } from './merge';

const type = 'UserSettings' as const;
const api = repositoryGetter();

export interface IUserSettings {
  id: string;
  type: typeof type;
  widgetTreeOverride: JsonPatch;
}

export const getUserSettings = () => {
  return api
    .getEntitiesList<IUserSettings>({ limiter: { type } })
      .then((response) => response.results[0])
};

const createUserSettings = (patch: JsonPatch) => {
  return api.createEntity({
    id: '',
    type,
    widgetTreeOverride: patch
  });
};

const updateUserSettings = (id: string, patch: JsonPatch) => {
  return api.updateEntity({
    id,
    type,
    widgetTreeOverride: patch
  });
};

export const setUserSettings = (patch: JsonPatch): Promise<void> => {
  // @ts-ignore
  return getUserSettings().then((settings) => {
    if (settings && 'id' in settings) {
      return updateUserSettings(settings.id as string, patch);
    } else {
      return createUserSettings(patch);
    }
  });
};
